/*
 *  boxed-layout.less
 * -----------------------------------------------
*/
.boxed-layout {
	@media only screen and (min-width : 768px) {
		.container {
			max-width: 1170px;
			padding-left: 30px;
			padding-right: 30px;
		}
		.header,
		.main-content,
		.footer {
			margin: 0 auto;
			max-width: 1170px;
		}
	}
	.header,
	section,
	.footer {
		background-color: @white-base;
	}
	.header {
		margin-bottom: 0;
		.navbar-default {
			background-color: @white-base;
			border-color: @white-base;
			padding: 0;

			.navbar-nav {
				> li {
					> a {
						padding: 30px 5px;
					}	
				}
			}
			.navbar-collapse {
				background-color: @white-base;
				border-color: @white-base;
				margin-right: 30px;
			}
		}
		#header-logo {
			margin: 0;
			padding-top: 27px;
		}
	}
}

@media only screen and (max-width : 991px) {
	.boxed-layout {
		padding-top: 0;
		.header {
			.navbar-default {
				.navbar-nav {
					> li {
						> a {
							padding: 10px;
						}
					}
				}
				.navbar-collapse {
					margin-right: 15px;
				}
			}
		}
		.navbar-header {
			padding: 15px 0;
		}
		.navbar-collapse {
			.navbar-nav {
				li {
					a {
						.caret {
							margin-right: 0;
						}
					}	
				}
			}
		}
	}
}