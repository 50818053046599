@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        MediZone
Version:        1.0
Last change:    04.25.2016
Primary use:    Health & Medical HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-medizone/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
@import "../css/elegant-icons.css";
@import "../css/stroke-gap-icons.css";
@import "../css/flaticon-set-dental.css";
@import "../css/flaticon-set-medical.css";
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto);

// Initialize Variables
@import "less-medizone/variables.less";
@import "less-medizone/mixins.less";

// Typography
@import "less-medizone/typography.less";

// Common Styles
@import "less-medizone/common.less";
@import "less-medizone/extra.less";
@import "less-medizone/overlay.less";

// Header
@import "less-medizone/header.less";

// Nav
@import "less-medizone/nav.less";

// Content Blocks
@import "less-medizone/topbar.less";
@import "less-medizone/inner-header-title.less";
@import "less-medizone/vertical-nav.less";
@import "less-medizone/menu-full-page.less";
@import "less-medizone/boxed-layout.less";
@import "less-medizone/form.less";
@import "less-medizone/side-push-panel.less";
@import "less-medizone/box-hover-effect.less";
@import "less-medizone/gallery-isotope.less";
@import "less-medizone/sliders.less";
@import "less-medizone/home.less";
@import "less-medizone/about.less";
@import "less-medizone/causes.less";
@import "less-medizone/volunteer.less";
@import "less-medizone/contact.less";
@import "less-medizone/event.less";
@import "less-medizone/practice-area.less";
@import "less-medizone/services.less";
@import "less-medizone/job.less";
@import "less-medizone/shop.less";
@import "less-medizone/blog.less";
@import "less-medizone/departments.less";

// Shortcodes
@import "less-medizone/shortcodes.less";


// Widgets
@import "less-medizone/widgets.less";


// Footer
@import "less-medizone/footer.less";