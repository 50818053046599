/*  
 * extra.less 
 * -----------------------------------------------
*/

/* -------- Heading Style ---------- */
.heading-border {
  border-bottom: 1px solid @gray-lightgray;
  margin: 30px 0;
  padding: 20px 0;
}

/* -------- FullCalendar - Plugin ---------- */
.fc-day-number {
  color: @black-333;
  
  &.fc-other-month {
    opacity: 0.4;
  }
}
/* scrolltofixed-container */
.scrolltofixed-container {
  position: static;
}
/* slider revolution */
.tp-bannertimer {
  z-index: 21 !important;
}
/* -------- Opening Hours ---------- */
.opening-hours ul li {
  border-bottom: 1px dashed @gray-lighter;
  padding: 5px 0 10px;
}
.opening-hours ul li .value {
	float: right;
}
/* -------- Opening Hours White ---------- */
.opening-hours.white ul li {
	color: @white-base;
	font-weight: 300;
}
.opening-hours.white ul li span {
	font-weight: 600;
}