/*
 * Shortcode: countdown-timer.less
 * -----------------------------------------------
*/



/* -------- My-Soon-Watch-Red ---------- */
#countdown-timer-soon-watch-red {
	background-color:#030303;
	color:#ffffff;
	font-family:"Comfortaa",sans-serif;

	.soon-reflection {
		background-color:#030303;
		background-image:linear-gradient(#030303 25%,rgba(3,3,3,0));
	}

	.soon-label {
		color:#ffffff;
		color:rgba(255,255,255,0.75);
	}

	.soon-ring-progress {
		background-color:#410918;
		border-top-width:14px;
		border-bottom-width:13px;
	}
}

/* -------- Soon-Scifi ---------- */
#countdown-timer-soon-scifi {
    color:#313857;
    text-transform:lowercase;

	.soon-reflection {
	    background-color:#090e14;
	    background-image:linear-gradient(#090e14 0, rgba(9, 14, 20, 0));
	}

	.soon-matrix-dot {
		background-color:transparent;
		&:nth-child(1)[data-state='1'] {
			background-color:#3559ff;
		}

		&:nth-child(2)[data-state='1'] {
			background-color:#1c46e7;
		}

		&:nth-child(3)[data-state='1'] {
			background-color:#1738ba;
		}

		&:nth-child(4)[data-state='1'] {
			background-color:#0f257b;
		}

		&:nth-child(5)[data-state='1'] {
			background-color:#0a1854;
		}

	}
}

/* -------- My-Soon-Orange ---------- */
#countdown-timer-soon-orange {
	background-color:#ffffff;
	color:#141541;
	font-family:"Source Sans Pro",sans-serif;

	.soon-reflection  {
		background-color:#ffffff;
		background-image:linear-gradient(#ffffff 25%,rgba(255,255,255,0));
	}

	.soon-label {
		color:#fb801b;
	}

	.soon-ring-progress {
		background-color:#f1f1f1;
		border-top-width:4px;
		border-bottom-width:2px;
	}
}

/* -------- Soon-Product ---------- */
#countdown-timer-soon-product{
    position:relative;
    text-align: left;
    font-family: 'Chewy', sans-serif;
    color:#fff;
    padding:.5em 1em 5em 1em;
    background-color:#000;
    background-image:url('images/countdown/shop.jpg');
    background-repeat:no-repeat;
    background-size:cover;
    text-shadow:0 .125em .25em rgba(0,0,0,.25);

	.soon-placeholder {
    display:block;
    color:#00CCFF;
    font-weight:bold;
	}

	.soon-label,
	.soon-valu{
		font-size:1.5em;
	}
}

/* -------- Soon-Rev ---------- */
#countdown-timer-soon-rev{
    font-family: 'Josefin Slab', serif;
    color:#31305b;

	.soon-label  {
		color:#823953;
	}
}

/* -------- Soon-Prog ---------- */
#countdown-timer-soon-prog {
	.soon-label {
    text-transform:lowercase;
    color:#424242;
	}

	.soon-value {
		color:#424242;
	}

	.soon-fill-inner {
		background-color:#E9E9E9;
	}
	.soon-fill-progress {
		background-color:#fafafa;
	}
}

/* -------- Soon-Cartoon ---------- */
#countdown-timer-soon-cartoon {
    font-family: 'Port Lligat Slab', serif;
    color:#F5ECB7;

	.soon-label {
		color:#F57576;
	}
}

/* -------- Soon-Klas ---------- */
#countdown-timer-soon-klas {
    text-align:center;
    font-family: 'Source Sans Pro', sans-serif;
    color:#fff;
    text-shadow:0 0 .125em rgba(255,255,255,.5);

    .soon-placeholder {
    	font-size:2em;
    }

    .soon-label {
        font-weight: bold;
        text-align: left;
        margin-left:.5em;
        margin-top:-1.5em;
        font-size:.2em;
    }
}

/* -------- Soon-Radi ---------- */
#countdown-timer-soon-radi {
	.soon-label {
		color:#ffbe00;
	}
	.soon-matrix-row:nth-child(1) {
		.soon-matrix-dot[data-state='1'] {
			background-color:red;
		}
	}
	.soon-matrix-row:nth-child(2) {
		.soon-matrix-dot[data-state='1'] {
			background-color:#ff1900;
		}
	}
	.soon-matrix-row:nth-child(3) {
		.soon-matrix-dot[data-state='1'] {
			background-color:#ff4400;
		}
	}
	.soon-matrix-row:nth-child(4) {
		.soon-matrix-dot[data-state='1'] {
			background-color:#ff6700;
		}
	}
	.soon-matrix-row:nth-child(5) {
		.soon-matrix-dot[data-state='1'] {
			background-color:#ff8300;
		}
	}
	.soon-matrix-row:nth-child(6) {
		.soon-matrix-dot[data-state='1'] {
			background-color:#ff9a00;
		}
	}
	.soon-matrix-row:nth-child(7) {
		.soon-matrix-dot[data-state='1'] {
			background-color:#ffbe00;
		}
	}
}

/* -------- Soon-Sep ---------- */
#countdown-timer-soon-sep {
    font-family:"Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",sans-serif;
    text-align:center;
    color:#1E99DD;

   .soon-placeholder {
        color:#fff;
        display:block;
   } 

   .soon-lbl {
   		font-size:.75em;
   }

   .soon-reflection {
        background-image:linear-gradient(#5fc6fd 25%, rgba(95, 198, 253, 0));
        opacity:.4;
   }

   .soon-tagline {
   		color:#1E99DD;
   }
}

/* -------- Soon-Glow ---------- */
#countdown-timer-soon-glow {
    font-family: 'Quicksand', sans-serif;
    color:#fff;
    text-transform:lowercase;

	.soon-label {
        color:#fff;
        text-shadow:0 0 .25rem rgba(255,255,255,.75);
	}

	.soon-ring-progress {
		color:#fff;
	}
}

/* -------- Soon-Espa ---------- */
#countdown-timer-soon-espa {

    .soon-label {
        color:#605951;
    }

    .soon-reflection {
        opacity:.1;
        background-color:#dedede;
        background-image:linear-gradient(#dedede 25% , rgba(222, 222, 222, 0));
    }

} 
