/*
 * departments.less
 * -----------------------------------------------
*/
.department-isotope {
	.gallery-item {
		.content {
			background: @white-f5;
		}
		&:hover .content {
			background: @gray-lightgray;
		}
	}
}